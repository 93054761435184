import { computed, defineComponent, ref } from "@vue/composition-api";
import { capitalizeWord } from "@/utils/string";
import { useRedirect } from "@/composable/use-redirect";
import { finishEvent } from "@/services/event/finish-event";
import { cancelEvent } from "@/services/event/cancel-event";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "CloseEventForm",
    props: {
        event: {
            type: null,
            required: true,
        },
        closingStatus: {
            type: Object,
            required: true,
        },
    },
    emits: {
        "discard-closing": null,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { redirect } = useRedirect();
        const reason = ref("");
        const reasonFulfilled = computed(() => reason.value !== "");
        const setReason = (value) => {
            reason.value = value;
        };
        const actionName = computed(() => props.closingStatus.finish ? "завершення" : "скасування");
        const closeEvent = async () => {
            if (props.event === null)
                return;
            if (props.closingStatus.finish) {
                await finishEvent.run(props.event.id, reason.value);
                await redirect("closed-event", { id: props.event.id });
            }
            if (props.closingStatus.cancel) {
                await cancelEvent.run(props.event.id, reason.value);
                await redirect("canceled-event", { id: props.event.id });
            }
        };
        const discardClosing = () => {
            emit("discard-closing", null);
        };
        return {
            reason,
            reasonFulfilled,
            setReason,
            actionName,
            closingEvent: computed(() => cancelEvent.active.value || finishEvent.active.value),
            closeEvent,
            discardClosing,
            capitalizeWord,
        };
    },
});
