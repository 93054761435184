import { updateEvent } from "./api";
import { ApiServerError, ApiValidationError } from "@/services/api";
import { flatErrorArray, TransformUnknownError, TransformValidationError } from "@/services/transform";
import { Event } from "@/models/Event";
import { fail, success } from "@/utils/either";
export async function transformAndUpdateEvent(eventId, eventData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    try {
        const result = await updateEvent(eventId, {
            name: eventData.name,
            type: eventData.type,
            zone: eventData.zone,
            coordinates: {
                latitude: eventData.coordinate.latitude,
                longitude: eventData.coordinate.longitude,
            },
            time: eventData.creationTime,
            activated: eventData.activationTime,
            creator: eventData.creator,
            owners: eventData.owners,
            leaders: eventData.leaders,
            victims: eventData.victims,
            description: eventData.description,
            sequence: JSON.stringify(eventData.sequence),
            involved: {
                rescuers: eventData.involvedRescuers,
                vehicles: eventData.involvedVehicles,
            },
        });
        if (result.isOk()) {
            return success(new Event(result.value));
        }
        else {
            if (result.value instanceof ApiValidationError) {
                const validationErrorsTmpl = await result.value.getObjectErrors();
                const transformError = new TransformValidationError({
                    name: (_a = validationErrorsTmpl.name) !== null && _a !== void 0 ? _a : [],
                    type: (_b = validationErrorsTmpl.type) !== null && _b !== void 0 ? _b : [],
                    zone: (_c = validationErrorsTmpl.zone) !== null && _c !== void 0 ? _c : [],
                    coordinate: (_d = validationErrorsTmpl.coordinates) !== null && _d !== void 0 ? _d : [],
                    creationTime: (_e = validationErrorsTmpl.time) !== null && _e !== void 0 ? _e : [],
                    activationTime: (_f = validationErrorsTmpl.activated) !== null && _f !== void 0 ? _f : [],
                    creator: (_g = validationErrorsTmpl.creator) !== null && _g !== void 0 ? _g : [],
                    owners: validationErrorsTmpl.owners === undefined ? [] : flatErrorArray(validationErrorsTmpl.owners),
                    leaders: validationErrorsTmpl.leaders === undefined ? [] : flatErrorArray(validationErrorsTmpl.leaders),
                    victims: (_h = validationErrorsTmpl.victims) !== null && _h !== void 0 ? _h : [],
                    description: (_j = validationErrorsTmpl.description) !== null && _j !== void 0 ? _j : [],
                    sequence: (_k = validationErrorsTmpl.sequence) !== null && _k !== void 0 ? _k : [],
                    involvedRescuers: (_m = (_l = validationErrorsTmpl.involved) === null || _l === void 0 ? void 0 : _l[0].rescuers) !== null && _m !== void 0 ? _m : [],
                    involvedVehicles: (_p = (_o = validationErrorsTmpl.involved) === null || _o === void 0 ? void 0 : _o[0].vehicles) !== null && _p !== void 0 ? _p : [],
                });
                return fail(transformError);
            }
            else if (result.value instanceof ApiServerError) {
                return fail(result.value);
            }
            else {
                return fail(new TransformUnknownError(result.value));
            }
        }
    }
    catch (e) {
        return fail(new TransformUnknownError(e));
    }
}
