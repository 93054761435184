import { computed, ref, watch } from "@vue/composition-api";
import { useCloseEvent } from "@events/composable/close-event";
export function useActionButtons({ eventId, dataFulfilled, eventUpdating, updateEvent, }) {
    const { eventClosingStatus, setEventClosingType: setEventClosingTypeComposable, clearEventClosingType, } = useCloseEvent();
    // edit button
    const editingDisabled = ref(true);
    const editButtonDisabled = computed(() => eventUpdating.value);
    const toggleEventEditing = () => {
        editingDisabled.value = !editingDisabled.value;
    };
    // update button
    const updateButtonDisabled = computed(() => (!dataFulfilled.value || eventUpdating.value || editingDisabled.value));
    const updateEventHandler = async () => {
        editingDisabled.value = true;
        await updateEvent();
    };
    // close button
    const setEventClosingType = (type) => {
        setEventClosingTypeComposable(type);
        editingDisabled.value = true;
    };
    watch(eventId, clearEventClosingType);
    return {
        editingDisabled: computed(() => editingDisabled.value),
        editButtonDisabled,
        toggleEventEditing,
        updateButtonDisabled,
        updateEventHandler,
        eventClosingStatus,
        setEventClosingType,
        clearEventClosingType,
    };
}
