import useRouter from "./use-router";
export function useRedirect() {
    const router = useRouter();
    const redirect = async (name, params) => {
        if (params === undefined) {
            await router.value.push({ name });
            return;
        }
        const paramsArrEntries = Object.entries(params).map(([k, v]) => [k, String(v)]);
        const paramsObjEntries = Object.fromEntries(paramsArrEntries);
        await router.value.push({ name, params: paramsObjEntries });
    };
    return {
        redirect,
    };
}
