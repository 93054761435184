import { defineComponent, toRefs } from "@vue/composition-api";
import { formatDate } from "@/utils/date";
import { useDiff } from "./diff";
import { useResource } from "./resource";
export default defineComponent({
    name: "EventLogs",
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        eventFields: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const { eventId, eventFields } = toRefs(props);
        const { sourceIsEmpty, logs, logsDownloading, downloadEventLogs, compareWithPrev, compareWithNext, } = useResource({ eventId });
        const { fields } = useDiff({ logs, displayFields: eventFields.value });
        return {
            sourceIsEmpty,
            logs,
            logsDownloading,
            downloadEventLogs,
            compareWithPrev,
            compareWithNext,
            fields,
            formatDate,
        };
    },
});
