import { request, ApiValidationError, ApiServerError, ApiUnknownError } from "@/services/api";
import { fail, success } from "@/utils/either";
import { mapResponse2 as mapResponse } from "@/utils/map-checkable-response";
export async function updateEvent(eventId, data) {
    try {
        const response = await request.checkablePut(`/events/${eventId}`, data);
        const result = mapResponse(response, {
            400: ApiValidationError,
            500: ApiServerError,
        });
        if (result.isOk()) {
            return success(result.value);
        }
        else {
            return fail(result.value);
        }
    }
    catch (e) {
        return fail(new ApiUnknownError(e));
    }
}
