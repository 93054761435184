import { computed, ref } from "@vue/composition-api";
export function useSelectedEvent({ setCode, clearCode, }) {
    const event = ref(null);
    const eventId = ref(0);
    const eventIsActive = computed(() => event.value !== null && event.value.status === "active");
    const selectEvent = async (selectedEvent) => {
        eventId.value = selectedEvent.id;
        event.value = selectedEvent;
        setCode(event.value.type.code);
        setEventDownloaded(true);
    };
    const clearEvent = () => {
        event.value = null;
        eventId.value = 0;
        clearCode();
        setEventDownloaded(false);
    };
    const eventDownloaded = ref(false);
    const setEventDownloaded = (status) => {
        eventDownloaded.value = status;
    };
    return {
        event: computed(() => event.value),
        eventId: computed(() => eventId.value),
        eventDownloaded,
        eventIsActive,
        selectEvent,
        clearEvent,
    };
}
