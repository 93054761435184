import { computed, ref } from "@vue/composition-api";
export function useCloseEvent() {
    const closingType = ref("--none--");
    const eventClosingStatus = computed(() => ({
        empty: closingType.value === "--none--",
        finish: closingType.value === "finish",
        cancel: closingType.value === "cancel",
    }));
    const setEventClosingType = (type) => {
        closingType.value = type;
    };
    const clearEventClosingType = () => {
        closingType.value = "--none--";
    };
    return {
        eventClosingStatus,
        setEventClosingType,
        clearEventClosingType,
    };
}
