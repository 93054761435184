import { ref } from "@vue/composition-api";
import { useRedirect as useRedirectComposable } from "@/composable/use-redirect";
export function useRedirect() {
    const { redirect: redirectComposable } = useRedirectComposable();
    const activeEventsCollapseEl = ref(null);
    const redirect = async () => {
        var _a;
        await redirectComposable("active-events");
        (_a = activeEventsCollapseEl.value) === null || _a === void 0 ? void 0 : _a.open();
    };
    return {
        activeEventsCollapseEl,
        redirect,
    };
}
