import { computed } from "@vue/composition-api";
import { isString } from "@/utils/check-types-runtime";
import { DATA_TRANSFORMERS, NAME_TEXTS } from "./data";
import { isSupportedField } from "./fields";
export function useDiff({ logs, displayFields }) {
    const getFieldData = (type, key) => {
        if (logs.value === null)
            return null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return DATA_TRANSFORMERS[key](logs.value[type].data[key]);
    };
    const fields = computed(() => {
        if (logs.value === null)
            return [];
        return displayFields.filter(field => isSupportedField(field)).map(field => {
            const currentData = getFieldData("current", field);
            const prevData = getFieldData("prev", field);
            if (isString(currentData) && isString(prevData)) {
                return {
                    type: "string",
                    name: NAME_TEXTS[field],
                    currentValue: currentData,
                    prevValue: prevData,
                    classes: { changed: currentData !== prevData },
                };
            }
            else if (Array.isArray(currentData) && Array.isArray(prevData)) {
                const type = "array";
                const currentValue = [];
                const prevValue = [];
                let prevIndex = 0;
                let currentIndex = 0;
                while (true) {
                    const current = currentData[currentIndex];
                    const prev = prevData[prevIndex];
                    if (current === undefined && prev === undefined)
                        break;
                    if (current === prev) {
                        prevValue.push({ value: prev });
                        currentValue.push({ value: current });
                        prevIndex += 1;
                        currentIndex += 1;
                        continue;
                    }
                    const prevExist = currentData.includes(prev);
                    const currentExist = prevData.includes(current);
                    if (prevExist && currentExist) {
                        prevValue.push({ value: prev });
                        currentValue.push({ value: current });
                        prevIndex += 1;
                        currentIndex += 1;
                        continue;
                    }
                    if (!prevExist && !currentExist) {
                        if (prev !== undefined)
                            prevValue.push({ value: prev, classes: ["removed"] });
                        if (current !== undefined)
                            currentValue.push({ value: current, classes: ["added"] });
                        prevIndex += 1;
                        currentIndex += 1;
                        continue;
                    }
                    if (!prevExist) {
                        prevValue.push({ value: prev, classes: ["removed"] });
                        currentValue.push({ value: Math.random().toString(), classes: ["empty"] });
                        prevIndex += 1;
                        continue;
                    }
                    if (!currentExist) {
                        currentValue.push({ value: current, classes: ["added"] });
                        prevValue.push({ value: Math.random().toString(), classes: ["empty"] });
                        currentIndex += 1;
                        continue;
                    }
                }
                return {
                    type,
                    name: NAME_TEXTS[field],
                    currentValue,
                    prevValue,
                };
            }
            else
                throw new Error("NOT SUPPORTED");
        });
    });
    return {
        fields,
    };
}
