import { defineComponent } from "@vue/composition-api";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventCloseButtons",
    emits: {
        "start-closing": null,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const startEventClosing = (status) => {
            emit("start-closing", status);
        };
        return {
            startEventClosing,
        };
    },
});
