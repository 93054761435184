export const SUPPORTED_FIELDS = [
    "name",
    "type",
    "zone",
    "coordinate",
    "description",
    // "status",
    "creationTime",
    // "activationTime",
    "creator",
    "victims",
    // "owners",
    "leaders",
    "sequence",
    "involvedRescuers",
    "involvedVehicles",
];
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isSupportedField = (field) => {
    return SUPPORTED_FIELDS.includes(field);
};
