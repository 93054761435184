import { Event } from "./Event";
import { User } from "./User";
import { EventStage } from "./EventStage";
export class EventLog {
    constructor(eventLog) {
        this.id = eventLog.id;
        this.time = new Date(eventLog.created);
        this.created = new Date(eventLog.created);
        this.updated = new Date(eventLog.updated);
        this.author = eventLog.user === null ? null : new User(eventLog.user);
        this.data = new Event(eventLog.data);
        this.data.sequence = eventLog.data.timeline.map(s => new EventStage(s));
        this.stage = eventLog.stage;
    }
}
