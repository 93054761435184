import { updateEvent as serviceUpdateEvent, ValidationError } from "@/services/event/update-event";
export function useUpdateEvent({ eventId, eventData, clearEvent, reloadActiveEvents, setErrors, redirect, }) {
    const updateEvent = async () => {
        if (eventData.value === null)
            return;
        const result = await serviceUpdateEvent.run(eventId.value, eventData.value);
        if (result.isOk()) {
            alert("Події редаговано!");
            clearEvent();
            await redirect();
            await reloadActiveEvents();
        }
        if (result.isFail() && result.value instanceof ValidationError) {
            const errors = result.value.getErrors();
            setErrors(errors);
        }
    };
    return {
        eventUpdating: serviceUpdateEvent.active,
        updateEvent,
    };
}
