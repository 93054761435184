import { Department } from "./Department";
export class VehicleOwnership {
    constructor(belong) {
        this.id = belong.id;
        this.department = new Department(belong.location);
        this.appliance = belong.appliance;
        this.created = belong.created;
        this.updated = belong.updated;
        this.startDate = belong.date_starts;
        this.endDate = belong.date_ends;
    }
}
