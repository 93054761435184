/** Пошуково рятувальна група, яка підпорядковується обласному управлінню */
export class Group {
    constructor(group) {
        this.id = group.id;
        this.region = group.mru;
        this.title = group.title;
        this.vendor = group.vendor;
        this.created = group.created;
        this.updated = group.updated;
    }
}
