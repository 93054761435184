import { computed, ref } from "@vue/composition-api";
export function useEventCode() {
    const eventCode = ref("--none--");
    const isSOS = computed(() => {
        return ["emergency", "search"].includes(eventCode.value);
    });
    const isPlanned = computed(() => {
        return ["inspection", "training", "security", "else"].includes(eventCode.value);
    });
    const setCode = (code) => {
        eventCode.value = code;
    };
    const clearCode = () => {
        eventCode.value = "--none--";
    };
    return {
        isSOS,
        isPlanned,
        setCode,
        clearCode,
    };
}
