import { defineComponent } from "@vue/composition-api";
import EventsByFilters from "@events/components/EventsByFilters.vue";
import EventLogs from "@events/components/EventLogs/index.vue";
import CloseEventButtons from "@events/components/CloseEventButtons.vue";
import CloseEventForm from "@events/components/CloseEventForm.vue";
import { useValidationErrors } from "@/composable/validation-errors";
import { useEditingEventData } from "@events/composable/event-data";
import { useEditingEventFields } from "@events/composable/event-fields";
import { formatDate } from "@/utils/date";
import { useActiveEventsSource } from "./active-events-source";
import { useSelectedEvent } from "./selected-event";
import { useUpdateEvent } from "./update-event";
import { useIsChildView } from "./is-child-view";
import { useRedirect } from "./redirect";
import { useActionButtons } from "./action-buttons";
export default defineComponent({
    name: "ActiveEvents",
    components: {
        EventsByFilters,
        EventLogs,
        CloseEventButtons,
        CloseEventForm,
    },
    setup() {
        const { errors: eventErrors, setErrors, clearErrors } = useValidationErrors();
        const requiredFields = [
            "name",
            "type",
            "zone",
            "creationTime",
            "leaders",
            "sequence",
            "involvedRescuers",
        ];
        const visibleFields = [
            "type",
            "name",
            "zone",
            "coordinate",
            "creationTime",
            "activationTime",
            "creator",
            "victims",
            "owners",
            "leaders",
            "description",
            "sequence",
            "involvedRescuers",
            "involvedVehicles",
        ];
        const { activeEventsDownloading, activeEvents, reloadActiveEvents } = useActiveEventsSource();
        const { eventData, dataFulfilled, updateEventData } = useEditingEventData({ requiredFields });
        const { eventFields, handleEventFieldUpdate, handleCustomFieldUpdate, setCode, clearCode, } = useEditingEventFields({ clearErrors, visibleFields });
        const { isChildView } = useIsChildView();
        const { event, eventId, eventDownloaded, eventIsActive, selectEvent, clearEvent, } = useSelectedEvent({ setCode, clearCode });
        const { activeEventsCollapseEl, redirect } = useRedirect();
        const { updateEvent, eventUpdating, } = useUpdateEvent({ eventId, eventData, setCode, clearEvent, setErrors, reloadActiveEvents, redirect });
        const { editingDisabled, editButtonDisabled, toggleEventEditing, updateButtonDisabled, updateEventHandler, eventClosingStatus, setEventClosingType, clearEventClosingType, } = useActionButtons({ eventId, dataFulfilled, eventUpdating, updateEvent });
        return {
            activeEventsCollapseEl,
            activeEventsDownloading,
            activeEvents,
            event,
            eventId,
            eventDownloaded,
            eventIsActive,
            selectEvent,
            clearEvent,
            editingDisabled,
            editButtonDisabled,
            toggleEventEditing,
            updateButtonDisabled,
            updateEventHandler,
            eventClosingStatus,
            setEventClosingType,
            clearEventClosingType,
            eventFields,
            eventRequiredFields: requiredFields,
            handleEventFieldUpdate,
            handleCustomFieldUpdate,
            eventErrors,
            eventUpdating,
            dataFulfilled,
            updateEventData,
            updateEvent,
            formatDate,
            isChildView,
        };
    },
});
