import { computed, ref } from "@vue/composition-api";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createAsyncProcess(fn) {
    const active = ref(false);
    const run = async (...args) => {
        active.value = true;
        try {
            return await fn(...args);
        }
        finally {
            active.value = false;
        }
    };
    return {
        active: computed(() => active.value),
        run,
    };
}
