import { Group } from "@/models/Group";
/** Департамент, який відноситься до групи */
export class Department {
    constructor(department) {
        this.id = department.id;
        this.group = new Group(department.group);
        this.title = department.title;
        this.vendor = department.vendor;
        this.created = department.created;
        this.updated = department.updated;
    }
}
