import { defineComponent, computed, shallowRef, watch } from "@vue/composition-api";
import AppIconCheck from "@/components/global/icons/Check.vue";
import useRoute from "@/composable/use-route";
import { getEventsByFilters } from "@/services/event/get-events-by-filters";
import { formatDate } from "@/utils/date";
export default defineComponent({
    name: "EventsByFilters",
    components: {
        AppIconCheck,
    },
    props: {
        getEventsFilters: { type: Object, required: true },
        perPage: { type: Number, required: false },
        emptyText: { type: String, required: true },
        loadingText: { type: String, required: true },
        routeName: { type: String, required: true },
        routeParamsGenerator: { type: Function, required: true },
    },
    setup(props) {
        const route = useRoute();
        const selectedEventId = computed(() => Number(route.value.params.id));
        const events = shallowRef([]);
        const total = shallowRef(0);
        const page = shallowRef(1);
        const limit = props.perPage || props.getEventsFilters.limit || 20;
        const downloadEvents = async () => {
            const offset = (page.value - 1) * limit;
            const eventsData = await getEventsByFilters.run({ ...props.getEventsFilters, limit, offset });
            events.value = eventsData.data;
            total.value = eventsData.total;
        };
        watch(() => props.getEventsFilters, () => {
            page.value > 1 ? page.value = 1 : downloadEvents();
        }, { immediate: true, deep: true });
        watch(page, downloadEvents);
        return {
            selectedEventId,
            eventsDownloading: getEventsByFilters.active,
            events: computed(() => events.value),
            total: computed(() => total.value),
            count: computed(() => Math.ceil(total.value / limit)),
            page: computed(() => page.value),
            setPage: (x) => { page.value = x; },
            formatDate,
        };
    },
});
