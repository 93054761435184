import { VehicleOwnership } from "./VehicleOwnership";
export class Vehicle {
    constructor(vehicle) {
        this.id = vehicle.id;
        this.type = vehicle.type;
        this.ownership = new VehicleOwnership(vehicle.belong);
        this.title = vehicle.title;
        this.callSign = vehicle.call_sign;
        this.created = vehicle.created;
        this.updated = vehicle.updated;
        this.brand = vehicle.brand;
        this.licensePlate = vehicle.number;
        this.mileage = vehicle.odometer;
        this.yearOfManufacture = vehicle.year_of_manufacture;
        this.photo = vehicle.photo;
    }
}
