import { computed, ref } from "@vue/composition-api";
export function useEditingEventData({ requiredFields }) {
    const eventData = ref(null);
    const dataFieldsFulfilledCondition = computed(() => {
        if (eventData.value === null)
            return null;
        return {
            type: eventData.value.type !== 0,
            name: eventData.value.name !== "",
            zone: eventData.value.zone !== 0,
            creator: eventData.value.creator !== 0,
            victims: eventData.value.victims.length > 0,
            owners: eventData.value.owners.length > 0,
            leaders: eventData.value.leaders.length > 0,
            involvedRescuers: eventData.value.involvedRescuers.length > 0,
            involvedVehicles: eventData.value.involvedVehicles.length > 0,
            sequence: eventData.value.sequence.every(s => s.description !== ""),
            activationTime: eventData.value.activationTime instanceof Date,
            creationTime: eventData.value.creationTime instanceof Date,
            coordinate: eventData.value.coordinate.latitude !== 0 && eventData.value.coordinate.longitude !== 0,
            description: eventData.value.description !== "",
        };
    });
    const dataFulfilled = computed(() => requiredFields.every(f => { var _a; return (_a = dataFieldsFulfilledCondition.value) === null || _a === void 0 ? void 0 : _a[f]; }));
    const updateEventData = (data) => {
        eventData.value = data;
    };
    return {
        eventData: computed(() => eventData.value),
        dataFulfilled,
        updateEventData,
    };
}
