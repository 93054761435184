import { fetchEventsByFilters } from "./api";
import { Event } from "@/models/Event";
export async function fetchAndTransformEventsByFilters(options = {}) {
    const params = {};
    if (options.status !== undefined)
        params.status = options.status;
    if (options.zone !== undefined && options.zone !== 0)
        params.zone = options.zone;
    if (options.type !== undefined && options.type !== 0)
        params.type = options.type;
    if (options.startDate instanceof Date)
        params.time__gt = options.startDate.toISOString();
    if (options.endDate instanceof Date)
        params.closed__lt = options.endDate.toISOString();
    params.limit = options.limit || 50;
    params.offset = options.offset || 0;
    if (options.ordering !== undefined)
        params.ordering = options.ordering;
    const result = await fetchEventsByFilters(params);
    return { total: result.count, data: result.results.map(e => new Event(e)) };
}
