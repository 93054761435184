import { Vehicle } from "./Vehicle";
import { Zone } from "./Zone";
import { User } from "./User";
import { EventType } from "./EventType";
export class EventResponsibility {
    constructor(duration) {
        this.time = new Date(duration.time);
        this.user = new User(duration.user);
    }
}
export class Event {
    constructor(event) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.id = event.id;
        this.name = event.name;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.type = event.type === null ? new EventType({ id: 0 }) : new EventType(event.type);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.zone = event.zone === null ? new Zone({ id: 0 }) : new Zone(event.zone);
        this.coordinate = event.coordinates;
        this.status = event.status;
        this.creationTime = new Date(event.time);
        this.activationTime = event.activated ? new Date(event.activated) : null;
        this.closed = new Date(event.closed);
        this.created = new Date(event.created);
        this.updated = new Date(event.updated);
        this.creator = event.creator ? new User(event.creator) : null;
        this.owners = (_a = event.owners) === null || _a === void 0 ? void 0 : _a.map(o => new EventResponsibility(o));
        this.leaders = (_b = event.leaders) === null || _b === void 0 ? void 0 : _b.map(l => new EventResponsibility(l));
        this.victims = (_c = event.victims) === null || _c === void 0 ? void 0 : _c.map(v => new User(v));
        this.description = event.description;
        this.sequence = [];
        this.involvedRescuers = (_e = (_d = event.involved) === null || _d === void 0 ? void 0 : _d.rescuers) === null || _e === void 0 ? void 0 : _e.map(r => new User(r));
        this.involvedVehicles = (_g = (_f = event.involved) === null || _f === void 0 ? void 0 : _f.vehicles) === null || _g === void 0 ? void 0 : _g.map(v => new Vehicle(v));
    }
}
