class TransformBaseError extends Error {
    constructor(name) {
        super(name);
        this.name = name;
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TransformValidationError extends TransformBaseError {
    constructor(transformedErrors) {
        super("TRANSFORM_VALIDATION_ERROR");
        this.errors = transformedErrors;
    }
    getErrors() {
        return this.errors;
    }
}
export class TransformUnknownError extends Error {
    constructor(error) {
        super("TRANSFORM_UNKNOWN_ERROR");
        this.errorInstance = error instanceof Error ? error : new Error("TRANSFORM_EMPTY_ERROR");
    }
    getError() {
        return this.errorInstance;
    }
}
