import { computed } from "@vue/composition-api";
import { useEventCode } from "./event-code";
const PLANNED_EVENT_FIELDS = [
    "type",
    "name",
    "zone",
    "coordinate",
    "leaders",
    "description",
    "sequence",
    "involvedRescuers",
    "involvedVehicles",
];
const SOS_EVENT_FIELDS = [
    "type",
    "name",
    "zone",
    "coordinate",
    "creationTime",
    "creator",
    "victims",
    "leaders",
    "description",
    "sequence",
    "involvedRescuers",
    "involvedVehicles",
];
export function useEditingEventFields({ visibleFields, clearErrors, }) {
    const { isSOS, isPlanned, setCode, clearCode, } = useEventCode();
    const eventFields = computed(() => {
        let fields = visibleFields;
        if (isPlanned.value)
            fields = fields.filter(f => PLANNED_EVENT_FIELDS.includes(f));
        if (isSOS.value)
            fields = fields.filter(f => SOS_EVENT_FIELDS.includes(f));
        return fields;
    });
    const handleEventFieldUpdate = ({ field }) => {
        if (clearErrors === undefined)
            throw new Error("FUNCTION clearErrors IS NOT PROVIDED");
        if (field === "coordinateLatitude" || field === "coordinateLongitude") {
            clearErrors(["coordinate"]);
        }
        else {
            clearErrors([field]);
        }
    };
    const handleCustomFieldUpdate = ({ field, value }) => {
        if (field === "EVENT_TYPE_CODE") {
            setCode(value);
        }
    };
    return {
        eventFields,
        handleEventFieldUpdate,
        handleCustomFieldUpdate,
        setCode,
        clearCode,
    };
}
