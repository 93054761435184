import { getEventLogs as serviceGetEventLogs } from "@/services/event-log/get-event-logs";
import { computed, ref, shallowRef } from "@vue/composition-api";
export function useResource({ eventId }) {
    const allLogs = shallowRef([]);
    const currentLogIndex = ref(-1);
    const sourceIsEmpty = computed(() => currentLogIndex.value !== -1 && allLogs.value.length < 2);
    const logs = computed(() => {
        if (currentLogIndex.value === -1 || sourceIsEmpty.value)
            return null;
        return {
            canComparePrev: currentLogIndex.value !== -1 && currentLogIndex.value !== 1,
            canCompareNext: currentLogIndex.value !== -1 && currentLogIndex.value !== allLogs.value.length - 1,
            prev: allLogs.value[currentLogIndex.value - 1],
            current: allLogs.value[currentLogIndex.value],
        };
    });
    const downloadEventLogs = async () => {
        const logs = await serviceGetEventLogs.run(eventId.value);
        allLogs.value = logs;
        currentLogIndex.value = logs.length - 1;
    };
    const compareWithPrev = () => {
        currentLogIndex.value -= 1;
    };
    const compareWithNext = () => {
        currentLogIndex.value += 1;
    };
    return {
        sourceIsEmpty,
        logs,
        logsDownloading: serviceGetEventLogs.active,
        downloadEventLogs,
        compareWithPrev,
        compareWithNext,
    };
}
