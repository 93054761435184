import { computed, onBeforeMount, shallowRef } from "@vue/composition-api";
import { getActiveEvents } from "@/services/event/get-active-events";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function useActiveEventsSource() {
    const activeEvents = shallowRef([]);
    const downloadActiveEvents = async () => {
        const events = await getActiveEvents.run();
        activeEvents.value = events;
    };
    const reloadActiveEvents = async () => {
        activeEvents.value = [];
        await downloadActiveEvents();
    };
    onBeforeMount(downloadActiveEvents);
    return {
        activeEventsDownloading: getActiveEvents.active,
        activeEvents: computed(() => activeEvents.value),
        reloadActiveEvents,
    };
}
