import { formatDate } from "@/utils/date";
export const NAME_TEXTS = {
    type: "Тип події",
    name: "Назва/місце події",
    zone: "Зона",
    coordinate: "Координати",
    description: "Опис подій",
    // status: "Статус",
    creationTime: "Час отримання повідомлення",
    // activationTime: "Час старту події",
    creator: "Дані заявника",
    victims: "Дані постраждалого / постраждалих",
    // owners: "Дані відповідальних",
    leaders: "Дані керівників",
    sequence: "Хід подій",
    involvedRescuers: "Залучений персонал",
    involvedVehicles: "Залучений транспорт",
};
// const STATUS_TEXT: Record<Event["status"], string> = {
//   created: "Створена",
//   active: "Активна",
//   closed: "Завершена",
// };
export const DATA_TRANSFORMERS = {
    name: n => n,
    type: t => t.name,
    zone: z => z.name,
    coordinate: c => `${c.latitude.toString()}, ${c.longitude.toString()}`,
    description: d => d,
    // status: s => STATUS_TEXT[s],
    creationTime: ct => formatDate(ct),
    // activationTime: at => at === null ? "Подію не активовано" : formatDate(at),
    creator: c => { var _a; return c === null ? "Заявника не задано" : `${c.fullName} ${(_a = c.phone) !== null && _a !== void 0 ? _a : ""}`.trim(); },
    victims: vs => vs.map(v => { var _a; return `${v.fullName} ${(_a = v.phone) !== null && _a !== void 0 ? _a : ""}`.trim(); }),
    // owners: os => os.map(o => o.user.fullName),
    leaders: ls => ls.map(l => l.user.fullName),
    sequence: sq => sq.map(s => { var _a, _b; return `${formatDate(s.time)} - ${(_b = (_a = s.author) === null || _a === void 0 ? void 0 : _a.fullName) !== null && _b !== void 0 ? _b : "<автора не задано>"}\n\r${s.description} `; }),
    involvedRescuers: ir => ir.map(r => r.fullName),
    involvedVehicles: iv => iv.map(v => `${v.brand} - ${v.ownership.department.title}`),
};
